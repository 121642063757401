<ng-container ngPreserveWhitespaces>
  <app-translation translationKey="DEFAULT"></app-translation>
  <ng-container [ngSwitch]="biomarkerType">
    <span
      *ngSwitchCase="variantFilterType.geneFusion"
      i18n="DefaultBiomarkerFilterText.Notes@@fusion"
      >fusion</span
    >
    <span
      *ngSwitchCase="variantFilterType.tso500GeneFusion"
      i18n="DefaultBiomarkerFilterText.Notes@@fusion"
      >fusion</span
    >
    <span
      *ngSwitchCase="variantFilterType.smallVariant"
      i18n="DefaultBiomarkerFilterText.Notes@@small-variants"
      >small variants</span
    >
    <span
      *ngSwitchCase="variantFilterType.copyNumberVariant"
      i18n="DefaultBiomarkerFilterText.Notes@@cnv"
      >CNV</span
    >
    <span
      *ngSwitchCase="variantFilterType.nonGeneFusionRearrangement"
      i18n="DefaultBiomarkerFilterText.Notes@@rearrangement"
      >rearrangement</span
    >
    <span
      *ngSwitchCase="variantFilterType.rnaSpliceVariant"
      i18n="DefaultBiomarkerFilterText.Notes@@splice-variant"
      >splice variants</span
    >
  </ng-container>
  <app-translation translationKey="FILTER"></app-translation>
</ng-container>
