import { SORT_BY } from 'app/domain/home/case-list/case-list.component';

export const PAGE_SIZE_OPTIONS = [25, 50, 75];

export class Pager {
  page: number = 1;
  rowsPerPage: number = 50;
  total: number = 0;
  range: PagerRange = {
    start: 0,
    end: 0
  };
  pagerOptions = PAGE_SIZE_OPTIONS;
  rows: any[] = [];

  constructor(rows: any[]) {
    this.initializePager(rows);
  }

  initializePager(rows: any[]): void {
    this.rows = rows;
    this.total = Math.ceil(this.rows.length / this.rowsPerPage);
    this.setPagerRange();
  }

  pageHandler(pager: PagerConfig): void {
    this.page = pager.page;
    this.rowsPerPage = pager.rowsPerPage;
    this.total = Math.ceil(this.rows.length / this.rowsPerPage);
    this.setPagerRange();
  }

  updateRows(rows: any[]): void {
    this.initializePager(rows);
  }

  setPagerRange(): void {
    this.range = setPagerRange(this.page, this.rowsPerPage, this.rows.length);
  }

  getPaginatedRows<T>(): T[] {
    return (this.rows || []).slice(
      this.rowsPerPage * (this.page - 1),
      this.page * this.rowsPerPage
    );
  }
}

export function setPagerRange(
  currentPage: number,
  rowsPerPage: number,
  totalItems: number
): PagerRange {
  return {
    start: (currentPage - 1) * rowsPerPage + 1,
    end:
      (currentPage - 1) * rowsPerPage + rowsPerPage < totalItems
        ? (currentPage - 1) * rowsPerPage + rowsPerPage
        : totalItems
  };
}

export interface PagerConfig {
  page: number;
  rowsPerPage: number;
  total: number;
  totalItems?: number;
  previousPage?: number;
  nextPage?: number;
}

export interface PagerRange {
  start: number;
  end: number;
}

/**
 * Need a dedicated pagination interface for the home page as it has different
 * requirements
 */
export interface HomePagePagination {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
  sortBy: SORT_BY;
  sortOrder: SORT_ORDER;
  nextURL: string;
  previousURL: string;
  lastURL: string;
  firstURL: string;
  currentURL: string;
}

export enum SORT_ORDER {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

// Decrease by 1 as API page # is 0-based
export function getPageNumberForAPI(page: number): number {
  return page > 0 ? page - 1 : 0;
}

// Increase by 1 as API page # is 0-based
export function getPageNumberForDisplay(page: number): number {
  return page + 1;
}

export interface ApiPaginatedResponse {
  size: number;
  totalElements: number;
  totalPages: number;
  number: number;
}
