<form [formGroup]="filterForm" [ngClass]="displayAsPopup ? 'filter-form-dialog':'filter-form'" fxLayout="column">
  <!--Filter selection part-->
  <div *ngFor="let filterData of filtersList" class="filter-section-container">
    <div class="include-if-title" fxLayoutAlign="space-between center">
      <span ngPreserveWhitespaces>
        <span class="mat-body-2" i18n="VariantFilter.Notes@@include">Include</span>
        <ng-container *ngIf="filtersList.length > 1" [ngSwitch]="filterData.type">
          <span class="mat-body-2" *ngSwitchCase="filterTypes.geneFusion" i18n="VariantFilter.Notes@@fusion">fusion</span>
          <span class="mat-body-2" *ngSwitchCase="filterTypes.tso500GeneFusion" i18n="VariantFilter.Notes@@fusion">fusion</span>
          <span class="mat-body-2" *ngSwitchCase="filterTypes.rnaSpliceVariant" i18n="VariantFilter.Notes@@rna-splice-variant">splice variants</span>
          <span class="mat-body-2" *ngSwitchCase="filterTypes.smallVariant" i18n="VariantFilter.Notes@@small-variants">small variants</span>
          <span class="mat-body-2" *ngSwitchCase="filterTypes.copyNumberVariant" i18n="VariantFilter.Checkbox@@cnv">CNV</span>
          <span class="mat-body-2" *ngSwitchCase="filterTypes.nonGeneFusionRearrangement" i18n="VariantFilter.Checkbox@@rearrangements">rearrangements</span>
        </ng-container>
        <span class="mat-body-2" i18n="VariantFilter.Notes@@if">if:</span>
      </span>
    </div>
    <div class="filters-group" fxLayout="row" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutGap="20px" [ngSwitch]="filterData.type">
        <ng-container *ngSwitchCase="filterTypes.smallVariant">
          <ng-container *ngTemplateOutlet="smallVariantFilterTemplate; context: {svFormGroup: filterForm.get(filterTypes.smallVariant)}"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="filterTypes.geneFusion">
          <ng-container *ngTemplateOutlet="fusionFilterTemplate; context: { fusionFormGroup: filterForm.get(filterTypes.geneFusion) }"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="filterTypes.tso500GeneFusion">
          <ng-container *ngTemplateOutlet="tso500GeneFusionFilterTemplate; context: { tso500GeneFusionFormGroup: filterForm.get(filterTypes.tso500GeneFusion) }"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="filterTypes.copyNumberVariant">
          <ng-container *ngTemplateOutlet="cnvFilterTemplate; context: { cnvFormGroup: filterForm.get(filterTypes.copyNumberVariant)}"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="filterTypes.nonGeneFusionRearrangement">
          <ng-container *ngTemplateOutlet="nonFusionRearangmentFilterTemplate; context: { nonFusionRearrangmentFormGroup: filterForm.get(filterTypes.nonGeneFusionRearrangement) }"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="filterTypes.rnaSpliceVariant">
          <ng-container *ngTemplateOutlet="rnaSpliceVariantFilterTemplate; context: { rnaSpliceVariantFormGroup: filterForm.get(filterTypes.rnaSpliceVariant) }"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="allowApplyFiltersForTierIAndII" class="filters-note" ngPreserveWhitespaces>
    <mat-checkbox class="checkbox-small" formControlName="ignoreFiltersForTierIAndII">
      <span class="mat-body-2" i18n="VariantFilter.Checkbox@@ignore-cosmic-tcga-and-germline-filters-for-tier-i-and-tier-ii-variants">Ignore COSMIC, TCGA and germline filters for Tier I and Tier II variants.</span>
    </mat-checkbox>
  </div>

  <!--Apply part-->
  <div class="filters-footer" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <span *ngIf="!manualMode" class="filters-footer-description no-wrap">
      <ng-container [ngSwitch]="filterError">
        <span *ngSwitchCase="'ERR_LOAD_FILTER'" i18n="VariantFilter.Notes@@unable-to-load-filter">Unable to load filter.</span>
        <span *ngSwitchCase="'ERR_APPLY_FILTER'" i18n="VariantFilter.Notes@@unable-to-apply-filter">Unable to apply filter.</span>
      </ng-container>
      <ng-container *ngIf="!filterError">
        <span i18n="VariantFilter.Notes@@this-is-the-default-filter" *ngIf="isDefaultFilter; else modifiedFilter">This is the default filter</span>
        <ng-template #modifiedFilter>
          <span ngPreserveWhitespaces>
            <span i18n="VariantFilter.Notes@@modified-filter-applied">Modified filter applied.</span>
            <span i18n="VariantFilter.Notes(underlineText)@@revert-to-default" class="underline-text" (click)="resetToDefault()">Revert to default</span>
          </span>
        </ng-template>
      </ng-container>
    </span>

    <div class="validation-info word-wrap">
      <span *ngFor="let alert of validations; let i = index; last as isLast">
        <ng-container *ngIf="filtersList.length > 1" [ngSwitch]="alert['type']">
          <span>[</span>
          <span *ngSwitchCase="filterTypes.geneFusion" i18n="VariantFilter.Checkbox@@fusion">Fusion</span>
          <span *ngSwitchCase="filterTypes.tso500GeneFusion" i18n="VariantFilter.Checkbox@@tso500GeneFusion">Fusion</span>
          <span *ngSwitchCase="filterTypes.smallVariant" i18n="VariantFilter.Notes@@small-variant">Small variant</span>
          <span *ngSwitchCase="filterTypes.copyNumberVariant" i18n="VariantFilter.Checkbox@@cnv">CNV</span>
          <app-translation *ngSwitchCase="filterTypes.rnaSpliceVariant" [translationKey]="filterTypes.rnaSpliceVariant"></app-translation>
          <span>]</span>
          <span>&nbsp;</span>
        </ng-container>
        <ng-container [ngSwitch]="alert['title']">
          <span *ngSwitchCase="'VAF'" i18n="VariantFilter.Notes@@vaf">VAF:</span>
          <span *ngSwitchCase="'cosmic_globalSiteCount'" i18n="VariantFilter.Notes@@found-in-cosmic">Found in COSMIC:</span>
          <span *ngSwitchCase="'tcga_globalCount'" i18n="VariantFilter.Notes@@found-in-tcga">Found in TCGA:</span>
          <span *ngSwitchCase="'RD'" i18n="VariantFilter.Notes@@rd">RD:</span>
          <span *ngSwitchCase="'pf_globalAlleleFreqDerived'" i18n="VariantFilter.Notes@@in-pf">in pf:</span>
          <span *ngSwitchCase="'cosmic_globalSomaticCount'" i18n="VariantFilter.Notes@@confirmed-in-cosmic">Confirmed in COSMIC:</span>
          <span *ngSwitchCase="'qualityScore'" i18n="VariantFilter.Notes@@quality-score">Quality score:</span>
          <span *ngSwitchCase="'supportingReadsPercentage'" i18n="VariantFilter.Notes@@fusion">Fusion %:</span>
          <span *ngSwitchCase="'supportingReadsCount'" i18n="VariantFilter.Notes@@fusion-supporting-rd">Fusion Supporting RD:</span>
          <span *ngSwitchCase="'supportingReadPairs'" i18n="VariantFilter.Notes@@supporting-read-pair">Supporting Read Pair:</span>
          <span *ngSwitchCase="'alleleFractionPercentage'" i18n="VariantFilter.Notes@@with-af">with AF:</span>
          <span *ngSwitchCase="'pValue'" i18n="VariantFilter.Notes@@p-value">p-value:</span>
          <span *ngSwitchCase="'lossCopyNumber'" i18n="VariantFilter.Notes@@copy-number-loss">Copy number loss:</span>
          <span *ngSwitchCase="'gainCopyNumber'" i18n="VariantFilter.Notes@@copy-number-gain">Copy number gain:</span>
          <span *ngSwitchCase="'lossVendorFoldChange'" i18n="VariantFilter.Notes@@fold-change-loss">Fold change loss:</span>
          <span *ngSwitchCase="'gainVendorFoldChange'" i18n="VariantFilter.Notes@@fold-change-gain">Fold change gain:</span>
          <span>&nbsp;</span>
        </ng-container>
        <ng-container [ngSwitch]="alert['message']">
          <strong *ngSwitchCase="'invalid'" i18n="VariantFilter.Notes@@field-pattern-validation">Invalid value</strong>
          <strong *ngSwitchCase="'missing'" i18n="VariantFilter.Notes@@missing-value">Missing value</strong>
        </ng-container>
        <span *ngIf="!isLast">, </span>
      </span>
    </div>

    <div fxLayoutGap="20px">
      <button type="button"
              mat-stroked-button one-secondary-button
              (click)="closeFilter()"
              i18n="VariantFilter.Button@@cancel-button-text">Cancel</button>
      <button type="button"
              mat-raised-button color="primary"
              [disabled]="isFilterButtonDisable()"
              (click)="emitOutput()">
        <span *ngIf="manualMode; else applyText" i18n="Set as filter|Button title@@setAsFilter">Set as filter</span>
        <ng-template #applyText i18n="Apply|Button title@@apply">Apply</ng-template>
      </button>
    </div>
  </div>
</form>


<!-- Small Variant Filter Template -->
<ng-template #smallVariantFilterTemplate let-svFormGroup="svFormGroup">
  <div fxLayout="row" fxLayoutGap="20px" [formGroup]="svFormGroup">
    <!--Somatic Filter-->
    <div class="single-filter-group" fxLayout="row">
      <div class="filter-details" formGroupName="somatic">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@known-somatic">known somatic</span>
        <div class="mat-body-2 filter-description" i18n="VariantFilter.Notes@@variant-is-present-in-one-or-more-of">variant is present in one or more of</div>

        <div class="checkbox-content" *ngIf="svFormGroup.get('somatic.cosmic_globalSiteCount')" fxLayout="row" formGroupName="cosmic_globalSiteCount" ngPreserveWhitespaces>
          <mat-checkbox class="checkbox-small" formControlName="cosmic_globalSiteCountCheck">
            <ng-container *ngTemplateOutlet="foundInCosmicText"></ng-container>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
                  [ngClass] = "svFormGroup.get('somatic.cosmic_globalSiteCount').valid?'filter-valid-border':'filter-invalid-border'"
                  class="input-filter"
                  formControlName="cosmic_globalSiteCountInput" spellcheck="false">
        </div>

        <div *ngIf="svFormGroup.get('somatic.cosmic_globalSomaticCount')" formGroupName="cosmic_globalSomaticCount" fxLayout="row" class="checkbox-content no-wrap" ngPreserveWhitespaces>
          <mat-checkbox class="checkbox-small" formControlName="cosmic_globalSomaticCountCheck">
            <span ngPreserveWhitespaces>
              <span class="mat-subheading-1" i18n="VariantFilter.Notes@@confirmed-in-cosmic">Confirmed in COSMIC</span> <span class="mat-body-2" i18n="VariantFilter.Notes@@with">with</span> <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@cosmic-annotation-disease-samples">samples</span>
            </span>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
                  [ngClass] = "svFormGroup.get('somatic.cosmic_globalSomaticCount').valid?'filter-valid-border':'filter-invalid-border'"
                  class="input-filter"
                  formControlName="cosmic_globalSomaticCountInput" spellcheck="false">
        </div>

        <div class="checkbox-content" *ngIf="svFormGroup.get('somatic.tcga_globalCount')" formGroupName="tcga_globalCount"  fxLayout="row" ngPreserveWhitespaces>
          <mat-checkbox class="checkbox-small" formControlName="tcga_globalCountCheck">
            <ng-container *ngTemplateOutlet="foundInTCGAText"></ng-container>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
                  [ngClass] = "svFormGroup.get('somatic.tcga_globalCount').valid?'filter-valid-border':'filter-invalid-border'"
                  class="input-filter"
                  formControlName="tcga_globalCountInput" spellcheck="false">
        </div>

      </div>
    </div>

    <!--Germline Filter-->
    <div class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>
      <div class="filter-details" formGroupName="germline">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@not-germline">not germline</span>
        <div class="mat-caption filter-description" i18n="VariantFilter.Notes(caption,filterDescription)@@exclude-if-present-in-population-frequency-database">exclude if present in population frequency database</div>

        <div class="checkbox-content" *ngIf="svFormGroup.get('germline.pf_globalAlleleFreqDerived')" formGroupName="pf_globalAlleleFreqDerived" fxLayout="row" ngPreserveWhitespaces>
          <mat-checkbox class="checkbox-small" formControlName="pf_globalAlleleFreqDerivedCheck">
            <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@with-af">with AF</span>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput #pf_globalAlleleFreqDerivedInput
                  [ngClass] = "svFormGroup.get('germline.pf_globalAlleleFreqDerived').valid? 'filter-valid-border':'filter-invalid-border'"
                  class="input-filter"
                  formControlName="pf_globalAlleleFreqDerivedInput"
                  spellcheck="false"> %
        </div>
        <div *ngIf="getFilterByType(filterTypes.smallVariant)?.popFreqSource; let popFreqSource;" class="mat-caption pop-freq-caption"><ng-container i18n="VariantFilter.Notes@@current-population-frequency-database-is">Current population frequency database is</ng-container> {{popFreqSource}}</div>
      </div>
    </div>

    <!-- Quality -->
    <div class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="quality">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>
        <div *ngIf="svFormGroup.get('quality.VAF')" class="quality-filter-lines checkbox-content filter-lines-first" formGroupName="VAF">
          <div class="quality-filter-value" fxLayout="row">
            <mat-checkbox class="checkbox-small" formControlName="VAFCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@vaf">VAF</span> &ge;
            </mat-checkbox>
            <input matInput #VAFInput
                  class="input-filter"
                  [ngClass] = "svFormGroup.get('quality.VAF').valid?'filter-valid-border':'filter-invalid-border'"
                  formControlName="VAFInput"
                  spellcheck="false"> %
          </div>
        </div>

        <div class="quality-and mat-caption" i18n="VariantFilter.Notes(qualityAnd,caption)@@and">and</div>

        <div *ngIf="svFormGroup.get('quality.RD')" class="quality-filter-lines" fxLayout="row" formGroupName="RD">
          <div class="quality-filter-value" fxLayout="row">
            <mat-checkbox class="checkbox-small" formControlName="RDCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@rd">RD</span>&nbsp; &ge;
            </mat-checkbox>
            <input matInput
                  class="input-filter"
                  [ngClass] = "svFormGroup.get('quality.RD').valid?'filter-valid-border':'filter-invalid-border'"
                  spellcheck="false"
                  formControlName="RDInput">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- CNV Filter Template -->
<ng-template #cnvFilterTemplate let-cnvFormGroup="cnvFormGroup">
  <div fxLayout="row" [formGroup]="cnvFormGroup">
    <!--Somatic Filter-->
    <div class="single-filter-group" fxLayout="row" fxLayoutGap="16px">
      <div class="filter-details" formGroupName="somatic">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@known-somatic">known somatic</span>
        <div class="mat-body-2" i18n="VariantFilter.Notes@@variant-is-present-in-one-or-more-of">variant is present in one or more of</div>

        <div class="checkbox-content" *ngIf="cnvFormGroup.get('somatic.cosmic_globalSiteCount')" formGroupName="cosmic_globalSiteCount" ngPreserveWhitespaces>
          <mat-checkbox class="checkbox-small" formControlName="cosmic_globalSiteCountCheck">
            <ng-container *ngTemplateOutlet="foundInCosmicText"></ng-container>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
                  [ngClass] = "cnvFormGroup.get('somatic.cosmic_globalSiteCount').valid?'filter-valid-border':'filter-invalid-border'"
                  class="input-filter"
                  formControlName="cosmic_globalSiteCountInput" spellcheck="false">
        </div>
      </div>
    </div>

    <!-- Copy number -->
    <div *ngIf="cnvFormGroup.get('copyNumber')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="copyNumber">
        <span 
          *ngIf="(cnvFormGroup.get('copyNumber.lossCopyNumber') || cnvFormGroup.get('copyNumber.gainCopyNumber')); else foldChangeLabel" 
          class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@copy-number-is">copy number is</span>
        <ng-template #foldChangeLabel>
          <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@fold-change-is">fold change is</span>
        </ng-template>

        <div *ngIf="cnvFormGroup.get('copyNumber.lossCopyNumber')" formGroupName="lossCopyNumber" fxLayout="row" class="filter-lines-first">
          <mat-checkbox class="checkbox-small" formControlName="lossCopyNumberCheck">
            <span class="mat-subheading-1"><app-translation translationKey="COPY_NUMBER"></app-translation></span> &lt;
          </mat-checkbox>
          <input matInput #lossCopyNumberInput formControlName="lossCopyNumberInput" class="input-filter"
            [ngClass]="cnvFormGroup.get('copyNumber.lossCopyNumber').valid?'filter-valid-border':'filter-invalid-border'"
            spellcheck="false">
        </div>

        <div *ngIf="cnvFormGroup.get('copyNumber.gainCopyNumber')" formGroupName="gainCopyNumber" fxLayout="row" class="filter-lines-first">
          <mat-checkbox class="checkbox-small" formControlName="gainCopyNumberCheck">
            <span class="mat-subheading-1"><app-translation translationKey="COPY_NUMBER"></app-translation></span> &gt;
          </mat-checkbox>
          <input matInput #gainCopyNumberInput formControlName="gainCopyNumberInput" class="input-filter"
            [ngClass]="cnvFormGroup.get('copyNumber.gainCopyNumber').valid?'filter-valid-border':'filter-invalid-border'"
            spellcheck="false">
        </div>

        <div *ngIf="cnvFormGroup.get('copyNumber.lossVendorFoldChange')" formGroupName="lossVendorFoldChange" fxLayout="row" class="filter-lines-first">
          <mat-checkbox class="checkbox-small" formControlName="lossVendorFoldChangeCheck">
            <span class="mat-subheading-1"><app-translation translationKey="FOLD_CHANGE"></app-translation></span> &lt;
          </mat-checkbox>
          <input matInput #lossVendorFoldChangeInput formControlName="lossVendorFoldChangeInput" class="input-filter"
            [ngClass]="cnvFormGroup.get('copyNumber.lossVendorFoldChange').valid?'filter-valid-border':'filter-invalid-border'"
            spellcheck="false">
        </div>

        <div *ngIf="cnvFormGroup.get('copyNumber.gainVendorFoldChange')" formGroupName="gainVendorFoldChange" fxLayout="row" class="filter-lines-first">
          <mat-checkbox class="checkbox-small" formControlName="gainVendorFoldChangeCheck">
            <span class="mat-subheading-1"><app-translation translationKey="FOLD_CHANGE"></app-translation></span> &gt;
          </mat-checkbox>
          <input matInput #gainVendorFoldChangeInput formControlName="gainVendorFoldChangeInput" class="input-filter"
            [ngClass]="cnvFormGroup.get('copyNumber.gainVendorFoldChange').valid?'filter-valid-border':'filter-invalid-border'"
            spellcheck="false">
        </div>

      </div>
    </div>

    <!-- Quality -->
    <div *ngIf="cnvFormGroup.get('quality')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="quality">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>

        <div *ngIf="cnvFormGroup.get('quality.qualityScore')" class="quality-filter-lines filter-lines-first" fxLayout="row" formGroupName="qualityScore">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="qualityScoreCheck">
              <ng-container *ngTemplateOutlet="qualityScoreText"></ng-container> &ge;
            </mat-checkbox>
            <input matInput #qualityScoreInput
                  class="input-filter"
                  [ngClass] = "cnvFormGroup.get('quality.qualityScore').valid?'filter-valid-border':'filter-invalid-border'"
                  formControlName="qualityScoreInput"
                  spellcheck="false">
          </div>
        </div>

        <div *ngIf="cnvFormGroup.get('quality.pValue')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="pValue">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="pValueCheck">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@p-value">p-value</span> &le;
            </mat-checkbox>
            <input matInput #pValueInput class="input-filter"
              [ngClass]="cnvFormGroup.get('quality.pValue').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="pValueInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="(cnvFormGroup.get('quality.qualityScore') || cnvFormGroup.get('quality.pValue')) && cnvFormGroup.get('quality.qualityEnum')" class="quality-and mat-caption" i18n="VariantFilter.Notes(qualityAnd,caption)@@and">and</div>

        <div *ngIf="cnvFormGroup.get('quality.qualityEnum')" class="quality-filter-lines filter-lines-first" fxLayout="row" fxLayoutGap="5px">
          <div class="quality-filter-value" formGroupName="qualityEnum">
            <mat-checkbox class="checkbox-small" formControlName="qualityEnumCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@cnv-call-is">CNV call is</span>
            </mat-checkbox>

            <div class="checkbox-filter-options" fxLayout="column" fxLayoutGap="4px">
              <mat-checkbox class="checkbox-small" formControlName="HIGH_CONFIDENCE_CNV_CALL">
                <span class="mat-subheading-1"  i18n="VariantFilter.Checkbox@@high-confidence">High confidence</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="LOW_CONFIDENCE_CNV_CALL">
                <span class="mat-subheading-1"  i18n="VariantFilter.Checkbox@@low-confidence">Low confidence</span>
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div *ngIf="cnvFormGroup.get('quality.equivocal')" class="quality-filter-lines filter-lines-first" fxLayout="row" fxLayoutGap="5px">
          <div class="quality-filter-value" formGroupName="equivocal">
            <mat-checkbox class="checkbox-small" formControlName="equivocalCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@equivocal-is">Equivocal is</span>
            </mat-checkbox>
            <div class="checkbox-filter-options" fxLayout="column" fxLayoutGap="4px">
              <mat-checkbox class="checkbox-small" formControlName="TRUE_EQUIVOCAL">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@true">True</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="FALSE_EQUIVOCAL">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@false">False</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- Fusion Filter Template -->
<ng-template #fusionFilterTemplate let-fusionFormGroup="fusionFormGroup">
  <div fxLayout="row" [formGroup]="fusionFormGroup">
    <!--Somatic Filter-->
    <div class="single-filter-group" fxLayout="row">
      <div class="filter-details" formGroupName="somatic">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@known-somatic">known somatic</span>
        <div class="mat-body-2" i18n="VariantFilter.Notes@@variant-is-present-in-one-or-more-of">variant is present in one or more of</div>
        <div class="checkbox-content" *ngIf="fusionFormGroup.get('somatic.cosmic_globalSiteCount')" formGroupName="cosmic_globalSiteCount">
          <mat-checkbox class="checkbox-small" formControlName="cosmic_globalSiteCountCheck">
            <ng-container *ngTemplateOutlet="foundInCosmicText"></ng-container>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
            [ngClass]="fusionFormGroup.get('somatic.cosmic_globalSiteCount').valid?'filter-valid-border':'filter-invalid-border'"
            class="input-filter" formControlName="cosmic_globalSiteCountInput" spellcheck="false">
        </div>
      </div>
    </div>

    <!-- Read realated -->
    <div *ngIf="fusionFormGroup.get('readRelated')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="readRelated">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@has-significant-portion-of-supporting-reads">has significant portion of supporting reads</span>
        <div *ngIf="fusionFormGroup.get('readRelated.supportingReadsPercentage')" formGroupName="supportingReadsPercentage"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="supportingReadsPercentageCheck">
              <span class="full-width"><span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@fusion">Fusion %</span> &ge;</span>
            </mat-checkbox>
            <input matInput #supportingReadsPercentageInput class="input-filter"
              formControlName="supportingReadsPercentageInput" spellcheck="false">
            <div i18n="VariantFilter.Notes(caption,supportingReadDetailText)@@supporting-reads-total-reads-depth" class="mat-caption supporting-read-detail-text">(Supporting Reads / Total Reads Depth)</div>
          </div>
        </div>
        <div *ngIf="fusionFormGroup.get('readRelated.supportingReadsCount')" formGroupName="supportingReadsCount"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="supportingReadsCountCheck">
            <span class="full-width"><span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@fusion-supporting-rd">Fusion Supporting RD</span></span> &ge;
          </mat-checkbox>
          <input matInput #supportingReadsCountInput class="input-filter"
            [ngClass]="fusionFormGroup.get('readRelated.supportingReadsCount').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="supportingReadsCountInput" spellcheck="false">
        </div>
      </div>
    </div>

    <!-- Inframe -->
    <div *ngIf="fusionFormGroup.get('inFrame')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="inFrame">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@is-inframe">is inframe</span>
        <div *ngIf="fusionFormGroup.get('inFrame.vendorAnnotatedInFrame')" class="quality-filter-lines filter-lines-first" fxLayout="row" fxLayoutGap="5px">
          <div class="quality-filter-value" formGroupName="vendorAnnotatedInFrame">
            <mat-checkbox class="checkbox-small" formControlName="vendorAnnotatedInFrameCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@inframeness-is">Inframeness is</span>
            </mat-checkbox>
            <div class="checkbox-filter-options" fxLayout="column" fxLayoutGap="4px">
              <mat-checkbox class="checkbox-small" formControlName="IN_FRAME_TRUE">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@true">True</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="IN_FRAME_FALSE">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@false">False</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="IN_FRAME_UNKNOWN">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@variant-oncogenicity-Unknown">Unknown</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quality -->
    <div *ngIf="fusionFormGroup.get('quality')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="quality">
        <span class="bold-text" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>
        <div *ngIf="fusionFormGroup.get('quality.qualityScore')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="qualityScore">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="qualityScoreCheck">
              <ng-container *ngTemplateOutlet="qualityScoreText"></ng-container> &ge;
            </mat-checkbox>
            <input matInput #qualityScoreInput class="input-filter"
              [ngClass]="fusionFormGroup.get('quality.qualityScore').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="qualityScoreInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="fusionFormGroup.get('quality.pValue')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="pValue">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="pValueCheck">
              <span class="mat-subheading-1"  i18n="VariantFilter.Checkbox@@p-value">p-value</span> &le;
            </mat-checkbox>
            <input matInput #pValueInput class="input-filter"
              [ngClass]="fusionFormGroup.get('quality.pValue').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="pValueInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="fusionFormGroup.get('quality.supportingReadPairs')" formGroupName="supportingReadPairs"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="supportingReadPairsCheck">
            <span class="full-width bold-text" i18n="VariantFilter.Checkbox@@supporting-read-pair">Supporting Read Pair</span> &ge;
          </mat-checkbox>
          <input matInput #supportingReadPairsInput class="input-filter"
            [ngClass]="fusionFormGroup.get('quality.supportingReadPairs').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="supportingReadPairsInput" spellcheck="false">
        </div>

        <div *ngIf="fusionFormGroup.get('quality.alleleFractionPercentage')" formGroupName="alleleFractionPercentage"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="alleleFractionPercentageCheck">
            <span class="full-width" ngPreserveWhitespaces><ng-container i18n="VariantFilter.Checkbox@@with">with</ng-container> <span class="bold-text" i18n="VariantFilter.Checkbox@@af">AF</span></span> &ge;
          </mat-checkbox>
          <input matInput #alleleFractionPercentageInput class="input-filter"
            [ngClass]="fusionFormGroup.get('quality.alleleFractionPercentage').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="alleleFractionPercentageInput" spellcheck="false"> %
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- TSO Fusion Filter Template -->
<ng-template #tso500GeneFusionFilterTemplate let-tso500GeneFusionFormGroup="tso500GeneFusionFormGroup">
  <div fxLayout="row" [formGroup]="tso500GeneFusionFormGroup">
    <!-- Somatic Filter -->
    <div class="single-filter-group" fxLayout="row">
      <div class="filter-details" formGroupName="somatic">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@known-somatic">known somatic</span>
        <div class="mat-body-2" i18n="VariantFilter.Notes@@variant-is-present-in-one-or-more-of">variant is present in one or more of</div>
        <div class="checkbox-content" *ngIf="tso500GeneFusionFormGroup.get('somatic.cosmic_globalSiteCount')" formGroupName="cosmic_globalSiteCount">
          <mat-checkbox class="checkbox-small" formControlName="cosmic_globalSiteCountCheck">
            <ng-container *ngTemplateOutlet="foundInCosmicText"></ng-container>
            <span>&ge;</span>
          </mat-checkbox>
          <input matInput
            [ngClass]="tso500GeneFusionFormGroup.get('somatic.cosmic_globalSiteCount').valid?'filter-valid-border':'filter-invalid-border'"
            class="input-filter" formControlName="cosmic_globalSiteCountInput" spellcheck="false">
        </div>
      </div>
    </div>

    <!-- Quality -->
    <div *ngIf="tso500GeneFusionFormGroup.get('quality')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="quality">
        <span class="bold-text" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>
        <div *ngIf="tso500GeneFusionFormGroup.get('quality.qualityScore')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="qualityScore">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="qualityScoreCheck">
              <ng-container *ngTemplateOutlet="qualityScoreText"></ng-container> &ge;
            </mat-checkbox>
            <input matInput #qualityScoreInput class="input-filter"
              [ngClass]="tso500GeneFusionFormGroup.get('quality.qualityScore').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="qualityScoreInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="tso500GeneFusionFormGroup.get('quality.pValue')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="pValue">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="pValueCheck">
              <span class="mat-subheading-1"  i18n="VariantFilter.Checkbox@@p-value">p-value</span> &le;
            </mat-checkbox>
            <input matInput #pValueInput class="input-filter"
              [ngClass]="tso500GeneFusionFormGroup.get('quality.pValue').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="pValueInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="tso500GeneFusionFormGroup.get('quality.supportingReadPairs')" formGroupName="supportingReadPairs"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="supportingReadPairsCheck">
            <span class="full-width bold-text" i18n="VariantFilter.Checkbox@@supporting-read-pair">Supporting Read Pair</span> &ge;
          </mat-checkbox>
          <input matInput #supportingReadPairsInput class="input-filter"
            [ngClass]="tso500GeneFusionFormGroup.get('quality.supportingReadPairs').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="supportingReadPairsInput" spellcheck="false">
        </div>

        <div *ngIf="tso500GeneFusionFormGroup.get('quality.alleleFractionPercentage')" formGroupName="alleleFractionPercentage"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="alleleFractionPercentageCheck">
            <span class="full-width" ngPreserveWhitespaces><ng-container i18n="VariantFilter.Checkbox@@with">with</ng-container> <span class="bold-text" i18n="VariantFilter.Checkbox@@af">AF</span></span> &ge;
          </mat-checkbox>
          <input matInput #alleleFractionPercentageInput class="input-filter"
            [ngClass]="tso500GeneFusionFormGroup.get('quality.alleleFractionPercentage').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="alleleFractionPercentageInput" spellcheck="false"> %
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- RNA Splice variant Filter Template -->
<ng-template #rnaSpliceVariantFilterTemplate let-rnaSpliceVariantFormGroup="rnaSpliceVariantFormGroup">
  <div fxLayout="row" [formGroup]="rnaSpliceVariantFormGroup">
    <!-- Quality -->
    <div *ngIf="rnaSpliceVariantFormGroup.get('quality')" class="single-filter-group" fxLayout="row">
      <div class="filter-details" formGroupName="quality">
        <span class="bold-text" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>
        <div *ngIf="rnaSpliceVariantFormGroup.get('quality.supportingReadPairs')" formGroupName="supportingReadPairs"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="supportingReadPairsCheck">
            <span class="full-width bold-text" i18n="VariantFilter.Checkbox@@supporting-read-pair">Supporting Read Pair</span> &ge;
          </mat-checkbox>
          <input matInput #supportingReadPairsInput class="input-filter"
            [ngClass]="rnaSpliceVariantFormGroup.get('quality.supportingReadPairs').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="supportingReadPairsInput" spellcheck="false">
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Non Fusion Rearrangement Filter Template -->
<ng-template #nonFusionRearangmentFilterTemplate let-nonFusionRearrangmentFormGroup="nonFusionRearrangmentFormGroup">
  <div fxLayout="row" [formGroup]="nonFusionRearrangmentFormGroup">
    <!-- Inframe -->
    <div *ngIf="nonFusionRearrangmentFormGroup.get('inFrame')" class="single-filter-group" fxLayout="row">
      <div class="filter-details" formGroupName="inFrame">
        <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@is-inframe">is inframe</span>
        <div *ngIf="nonFusionRearrangmentFormGroup.get('inFrame.vendorAnnotatedInFrame')" class="quality-filter-lines filter-lines-first" fxLayout="row" fxLayoutGap="5px">
          <div class="quality-filter-value" formGroupName="vendorAnnotatedInFrame">
            <mat-checkbox class="checkbox-small" formControlName="vendorAnnotatedInFrameCheck">
              <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@inframeness-is">Inframeness is</span>
            </mat-checkbox>
            <div class="checkbox-filter-options" fxLayout="column" fxLayoutGap="4px">
              <mat-checkbox class="checkbox-small" formControlName="IN_FRAME_TRUE">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@true">True</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="IN_FRAME_FALSE">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@false">False</span>
              </mat-checkbox>
              <mat-checkbox class="checkbox-small full-width" formControlName="IN_FRAME_UNKNOWN">
                <span class="mat-subheading-1" i18n="VariantFilter.Checkbox@@variant-oncogenicity-Unknown">Unknown</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Quality -->
    <div *ngIf="nonFusionRearrangmentFormGroup.get('quality')" class="single-filter-group" fxLayout="row">
      <div class="mat-body-2 and-text" i18n="VariantFilter.Notes(andText)@@and">AND</div>

      <div class="filter-details" formGroupName="quality">
        <span class="bold-text" i18n="VariantFilter.Notes@@is-good-quality">is good quality</span>
        <div *ngIf="nonFusionRearrangmentFormGroup.get('quality.qualityScore')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="qualityScore">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="qualityScoreCheck">
              <ng-container *ngTemplateOutlet="qualityScoreText"></ng-container> &ge;
            </mat-checkbox>
            <input matInput #qualityScoreInput class="input-filter"
              [ngClass]="nonFusionRearrangmentFormGroup.get('quality.qualityScore').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="qualityScoreInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="nonFusionRearrangmentFormGroup.get('quality.pValue')" class="quality-filter-lines filter-lines-first"
          fxLayout="row" formGroupName="pValue">
          <div class="quality-filter-value">
            <mat-checkbox class="checkbox-small" formControlName="pValueCheck">
              <span class="mat-subheading-1"  i18n="VariantFilter.Checkbox@@p-value">p-value</span> &le;
            </mat-checkbox>
            <input matInput #pValueInput class="input-filter"
              [ngClass]="nonFusionRearrangmentFormGroup.get('quality.pValue').valid?'filter-valid-border':'filter-invalid-border'"
              formControlName="pValueInput" spellcheck="false">
          </div>
        </div>

        <div *ngIf="nonFusionRearrangmentFormGroup.get('quality.supportingReadPairs')" formGroupName="supportingReadPairs"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="supportingReadPairsCheck">
            <span class="full-width bold-text" i18n="VariantFilter.Checkbox@@supporting-read-pair">Supporting Read Pair</span> &ge;
          </mat-checkbox>
          <input matInput #supportingReadPairsInput class="input-filter"
            [ngClass]="nonFusionRearrangmentFormGroup.get('quality.supportingReadPairs').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="supportingReadPairsInput" spellcheck="false">
        </div>

        <div *ngIf="nonFusionRearrangmentFormGroup.get('quality.alleleFractionPercentage')" formGroupName="alleleFractionPercentage"
          class="quality-filter-lines filter-lines-first" fxLayout="row">
          <mat-checkbox class="checkbox-small" formControlName="alleleFractionPercentageCheck">
            <span class="full-width" ngPreserveWhitespaces><ng-container i18n="VariantFilter.Checkbox@@with">with</ng-container> <span class="bold-text" i18n="VariantFilter.Checkbox@@af">AF</span></span> &ge;
          </mat-checkbox>
          <input matInput #alleleFractionPercentageInput class="input-filter"
            [ngClass]="nonFusionRearrangmentFormGroup.get('quality.alleleFractionPercentage').valid?'filter-valid-border':'filter-invalid-border'"
            formControlName="alleleFractionPercentageInput" spellcheck="false"> %
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Found in Cosmic Template -->
<ng-template #foundInCosmicText>
  <span ngPreserveWhitespaces>
    <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@found-in-cosmic">Found in COSMIC</span>
    <span class="mat-body-2" i18n="VariantFilter.Notes@@with">with</span>
    <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@cosmic-annotation-disease-samples">samples</span>
  </span>
</ng-template>

<!-- Found in TCGA Template -->
<ng-template #foundInTCGAText>
  <span ngPreserveWhitespaces>
    <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@found-in-tcga">Found in TCGA</span>
    <span class="mat-body-2" i18n="VariantFilter.Notes@@with">with</span>
    <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@cases">cases</span>
  </span>
</ng-template>

<!-- Quality score Template -->
<ng-template #qualityScoreText>
  <span class="mat-subheading-1" i18n="VariantFilter.Notes(subheading1)@@quality-score">Quality score</span>
</ng-template>
