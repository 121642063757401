export const VARIANT_FILTER_TYPE = {
  geneFusion: 'GeneFusion',
  tso500GeneFusion: 'TSO500_GENE_FUSION',
  nonGeneFusionRearrangement: 'NonGeneFusionRearrangement',
  smallVariant: 'SmallVariant',
  copyNumberVariant: 'CopyNumberVariant',
  TMB: 'TMB',
  MSI: 'MSI',
  MSI_JSD: 'MSI_JSD',
  HRD: 'HRD',
  GenomicLossOfHeterozygosity: 'GenomicLossOfHeterozygosity',
  OtherStructuralRearrangement: 'OtherStructuralRearrangement',
  OtherStructuralVariants: 'OtherStructuralVariants',
  rnaSpliceVariant: 'SPLICING_VARIANT'
};

export function bioMarkerNoFilter(filterType: string): boolean {
  // Does filtering not apply to this biomarker type?
  return [
    VARIANT_FILTER_TYPE.TMB,
    VARIANT_FILTER_TYPE.MSI,
    VARIANT_FILTER_TYPE.MSI_JSD,
    VARIANT_FILTER_TYPE.HRD,
    VARIANT_FILTER_TYPE.GenomicLossOfHeterozygosity,
    VARIANT_FILTER_TYPE.OtherStructuralRearrangement
  ].includes(filterType);
}

// TODO: Proposed variant filter structure.
export const VARIANT_FILTER = {
  [VARIANT_FILTER_TYPE.smallVariant]: {
    somatic: ['cosmic_globalSiteCount', 'cosmic_globalSomaticCount', 'tcga_globalCount'],
    germline: ['pf_globalAlleleFreqDerived'],
    quality: ['VAF', 'RD']
  },
  [VARIANT_FILTER_TYPE.copyNumberVariant]: {
    somatic: ['cosmic_globalSiteCount']
  },
  [VARIANT_FILTER_TYPE.geneFusion]: {
    somatic: ['cosmic_globalSiteCount']
  },
  [VARIANT_FILTER_TYPE.tso500GeneFusion]: {
    somatic: ['cosmic_globalSiteCount']
  },
  [VARIANT_FILTER_TYPE.nonGeneFusionRearrangement]: {},
  [VARIANT_FILTER_TYPE.rnaSpliceVariant]: {
    quality: []
  }
};
