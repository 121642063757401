import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { has as _has, get as _get, compact as _compact } from 'lodash';

import { CaseData } from 'app/model/entities/CaseList';
import { AuthService } from 'app/services/auth.service';
import { CaseService } from 'app/services/case.service';
import {
  ListName,
  pageSizesOptions,
  CaseListService,
  pageSizes
} from 'app/services/case-list.service';
import { Localization } from 'app/model/valueObjects/localization';
import { ToasterService } from 'app/services/toaster.service';
import { TableSort } from 'app/model/entities/OwcTableSortedDataSource';
import { PagerConfig, SORT_ORDER } from 'app/model/entities/pagination';

interface SubscriberMap {
  [caseID: string]: Observable<string>;
}

export enum SORT_BY {
  CREATED_AT = 'createdAt', // default
  CASE_ID = 'caseId',
  DAYS_SINCE_SAMPLE_RECEIPT = 'daysSinceSpecimenCollection',
  STATUS = 'status',
  DISEASE = 'diagnosis'
}

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent implements OnInit, OnDestroy {
  @Input() pagination = true;
  @Input() showDaysSinceColumn = false;
  @Input() cases$: Observable<CaseData[]>;
  @Input() enableColumnSorting = true;
  @Input() loading: Observable<boolean>;
  @Input() listName: ListName;
  @Input() totalCases: number;
  @Output() navigate = new EventEmitter<{}>();
  paginationPageSizeOptions = pageSizesOptions;
  sortByOptions = SORT_BY;
  pagerConfig$ = this.caseListService.pagerConfig$;
  sortConfig$ = this.sortConfigGetter$;
  checkListEmpty$: Observable<boolean>;
  pageSizes = pageSizes;

  private ngUnsubscribe = new Subject<void>();

  ListName = ListName;

  MAX_VARIANTS_TOBE_DISPLAYED_ON_LIST = 2;
  subscriberMap: SubscriberMap = {};

  get sortConfigGetter$(): Observable<TableSort> {
    return this.caseListService.activePageSortConfig$.pipe(
      map((sortConfig) => {
        return {
          column:
            sortConfig.column === SORT_BY.DAYS_SINCE_SAMPLE_RECEIPT
              ? 'daysSinceSpecimenCollection'
              : sortConfig.column,
          order: sortConfig.order
        };
      })
    );
  }

  get checkListEmptyGetter$(): Observable<boolean> {
    return this.cases$.pipe(map((results: CaseData[]) => results && results.length === 0));
  }

  constructor(
    private toasterService: ToasterService,
    public localization: Localization,
    private authService: AuthService,
    private caseListService: CaseListService
  ) {}

  ngOnInit(): void {
    this.checkListEmpty$ = this.checkListEmptyGetter$;
    this.cases$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((caseDataArray) => {
      caseDataArray.forEach((caseData) => {
        this.subscriberMap[caseData.id] = this.getSubscriberEmailList(caseData.subscribers);
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  pageChangeHandler(event: PagerConfig): void {
    this.caseListService.updatePagination(event);
  }

  sortChangeHandler(sortBy: SORT_BY, sortOrder: SORT_ORDER): void {
    this.caseListService.updateSorting(sortBy, sortOrder);
  }

  private hasPermission(caseData: CaseData, permission: string): boolean {
    return _has(caseData._links, permission);
  }

  navigateTo(caseData: CaseData): void {
    // TODO: Find alternate for event emitter for "Open in new tab" functionality
    if (CaseService.toEditPage(caseData.status) && !this.hasPermission(caseData, 'editCase')) {
      this.navigate.emit('');
      this.toasterService.info('You do not have permission to edit.');
    } else if (!this.hasPermission(caseData, 'viewCase')) {
      this.navigate.emit('');
      this.toasterService.info('You do not have permission to view the report.');
    } else {
      this.navigate.emit(CaseService.getNavURL(caseData.status, caseData.id));
    }
  }

  getSubscriberEmailList(caseSubscriberIDs: string[] = []): Observable<string> {
    const userNames$ = caseSubscriberIDs.map((userID) => this.authService.getUserName(userID));

    return combineLatest(userNames$).pipe(map((results: string[]) => results.join(', ')));
  }

  getOrderByTooltip(row: CaseData): string {
    return _compact([row.orderingPhysicianName, row.orderingInstituteName]).join(', ');
  }

  getVariantListByToolTip(row: CaseData): string {
    var variantList: string = '';
    const variantsCount = row.variants?.length || 0;
    if (variantsCount <= 2) return variantList;
    for (var i = 2; i < row.variants.length; i++) {
      const variantName = row.variants[i][0];
      variantList =
        variantList + _compact([variantName.first, variantName.last, variantName.alias]).join(' ');
      if (variantName.first && i < variantsCount - 1) variantList = variantList + '\n';
    }
    return variantList;
  }

  trackByCaseId(index: number, caseData: CaseData): string {
    return caseData.caseId;
  }
}
