import { CaseLinkKeys } from 'app/model/valueObjects/caseLinksKeys';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';
import { get as _get } from 'lodash';
import { combineLatest as observableCombineLatest, BehaviorSubject, Observable } from 'rxjs';

import { CaseService } from './case.service';
import { LabNote } from 'app/model/entities/lab-note';
import { AuthService } from './auth.service';
import { VariantService } from 'app/services/variant.service';
import { CaseActivity } from 'app/model/entities/caseActivity';
import { CaseEventType } from 'app/model/valueObjects/caseEventType';
import { VariantMap } from 'app/model/entities/annotatedVariant';

@Injectable()
export class LabNoteService {
  private _savingLabNote = new BehaviorSubject<boolean>(false);
  private _loadingLabNoteList = new BehaviorSubject<boolean>(false);
  private _labNoteList = new BehaviorSubject<LabNote[]>([]);
  private _labNoteListError = new BehaviorSubject<boolean>(false);
  private _loadingCaseActivity = new BehaviorSubject<boolean>(false);
  private _caseActivity = new BehaviorSubject<CaseActivity[]>([]);
  private _caseActivitiesError = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private caseService: CaseService,
    private variantService: VariantService,
    private authService: AuthService
  ) {}

  get savingLabNote(): Observable<boolean> {
    return this._savingLabNote.asObservable();
  }

  get loadingLabNoteList(): Observable<boolean> {
    return this._loadingLabNoteList.asObservable();
  }

  get labNoteList(): Observable<LabNote[]> {
    return this._labNoteList.asObservable();
  }

  get labNoteListError(): Observable<boolean> {
    return this._labNoteListError.asObservable();
  }

  get loadingCaseActivity(): Observable<boolean> {
    return this._loadingCaseActivity.asObservable();
  }

  get caseActivityError(): Observable<boolean> {
    return this._caseActivitiesError.asObservable();
  }

  augmentBulkEditList(caseActivityEntry: CaseActivity, variantMap: VariantMap) {
    const bulkEditVariantList = caseActivityEntry.additionalData?.variants ?? [];
    // Handle either undefined or null:
    const newTierValue =
      caseActivityEntry.additionalData?.reclassification?.tier ?? 'RemovedFromReport';

    bulkEditVariantList.forEach((variantEntry) => {
      variantEntry['variantObject'] = variantMap[variantEntry.variantId];
      variantEntry['fromValue'] = variantEntry.previousValues.tier;
      variantEntry['toValue'] = newTierValue;
    });
  }

  // Get Case Activity without variant mapping.
  get rawCaseActivity(): Observable<CaseActivity[]> {
    return this._caseActivity;
  }

  // Uses 'variantMap' from VariantService to attach
  // the full AnnotatedVariant object for display purposes
  get caseActivity(): Observable<CaseActivity[]> {
    return observableCombineLatest([this._caseActivity, this.variantService.variantMap]).pipe(
      map(([caseActivityArray, variantMap]) => {
        if (!variantMap) return [];

        return caseActivityArray.map((caseActivityEntry) => {
          const caseVariantId = _get(caseActivityEntry, 'additionalData.variant');

          if (caseVariantId) {
            caseActivityEntry.additionalData['variantObject'] = variantMap[caseVariantId];
          } else if (caseActivityEntry.eventType === CaseEventType.BULK_VAR_RECLASSIFIED) {
            this.augmentBulkEditList(caseActivityEntry, variantMap);
          }

          return caseActivityEntry;
        });
      })
    );
  }

  loadCaseActivity(caseId: string) {
    this._loadingCaseActivity.next(true);

    this.http
      .get<any[]>(this.authService.getURL('viewCaseHistory', { caseId }))
      .pipe(
        finalize(() => this._loadingCaseActivity.next(false)),
        map((res) => _get(res, ['_embedded', 'caseActions']))
      )
      .subscribe({
        next: (json: CaseActivity[]) => {
          this._caseActivity.next(json);
          this._caseActivitiesError.next(false);
        },
        error: () => this._caseActivitiesError.next(true)
      });
  }

  createLabNote(labNote) {
    this._savingLabNote.next(true);

    return this.http
      .post(this.caseService.getCurrentCaseLink(CaseLinkKeys.CREATE_LAB_NOTE), labNote)
      .pipe(finalize(() => this._savingLabNote.next(false)));
  }

  loadLabNoteList() {
    const url = this.caseService.getCurrentCaseLink(CaseLinkKeys.VIEW_LAB_NOTES);
    this._loadingLabNoteList.next(true);

    this.http
      .get<any>(url)
      .pipe(finalize(() => this._loadingLabNoteList.next(false)))
      .subscribe({
        next: (labNoteList) => {
          this._labNoteList.next(labNoteList._embedded.labNoteSummary);
          this._labNoteListError.next(false);
        },
        error: (err) => this._labNoteListError.next(true)
      });
  }
}
