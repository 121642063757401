<owc-table disable-scroll="true" height="auto" aria-label="Case list table" i18n-aria-label="CaseList.Header@@case-list-table">
  <owc-table-header sticky>
    <owc-table-header-cell class="case-id-table-header" [attr.sortable]="enableColumnSorting ? '' : null" appSortOrderIconSetter columnName="caseId" [sortConfig]="sortConfig$ | async" (sortChange)="sortChangeHandler(sortByOptions.CASE_ID, $event.detail)">
      <app-ruo-ivd-mapper>
        <span i18n="CaseList.ColumnHeader@@case-list-case-id" IVD>Case ID</span>
        <span i18n="CaseList.ColumnHeader@@research-case-id" RUO>Research case ID</span>
      </app-ruo-ivd-mapper>
    </owc-table-header-cell>
    <owc-table-header-cell class="days-since-specimen-collection-table-header" [attr.sortable]="enableColumnSorting ? '' : null" appSortOrderIconSetter columnName="daysSinceSpecimenCollection" [sortConfig]="sortConfig$ | async" (sortChange)="sortChangeHandler(sortByOptions.DAYS_SINCE_SAMPLE_RECEIPT, $event.detail)">
      <div class="days-since-specimen-collection-table-header-text" *ngIf="showDaysSinceColumn; else emptyCell" i18n="CaseList.ColumnHeader@@days-since-sample-receipt">Days since sample receipt</div>
    </owc-table-header-cell>
    <owc-table-header-cell class="status-table-header" [attr.sortable]="enableColumnSorting ? '' : null" appSortOrderIconSetter columnName="status" [sortConfig]="sortConfig$ | async" (sortChange)="sortChangeHandler(sortByOptions.STATUS, $event.detail)">
      <span class="text-align-left">
        <div i18n="CaseList.ColumnHeader@@status">Status</div>
        <div class="owc-caption cair-color-gray-400" i18n="CaseList.ColumnHeader@@changed-by">Changed by</div>
      </span>
    </owc-table-header-cell>
    <owc-table-header-cell class="diagnosis-table-header" i18n="CaseList.ColumnHeader@@disease" [attr.sortable]="enableColumnSorting ? '' : null" appSortOrderIconSetter columnName="diagnosis" [sortConfig]="sortConfig$ | async" (sortChange)="sortChangeHandler(sortByOptions.DISEASE, $event.detail)">
      Disease
    </owc-table-header-cell>
    <owc-table-header-cell class="variants-table-header" i18n="CaseList.ColumnHeader@@variants" columnName="variants">
      Variants
    </owc-table-header-cell>
    <owc-table-header-cell class="order-by-table-header" columnName="orderBy">
    </owc-table-header-cell>
    <owc-table-header-cell class="subscription-table-header" columnName="subscription">
      &nbsp;
    </owc-table-header-cell>
    <owc-table-header-cell class="acitvities-table-header" columnName="activities">
      &nbsp;
    </owc-table-header-cell>
    <owc-table-header-cell class="actions-table-header" columnName="actions">
      &nbsp;
    </owc-table-header-cell>
  </owc-table-header>

  <owc-table-body [loading]="loading | async" [ngClass]="{'owc-table-loader': loading | async}">
    <owc-table-row *ngFor="let row of (cases$ | async) trackBy: trackByCaseId">
      <owc-table-cell class="case-id-table-cell" (click)="navigateTo(row)">
        {{row.caseId}}
      </owc-table-cell>
      <owc-table-cell class="days-since-specimen-collection-table-cell" (click)="navigateTo(row)">
        <app-ruo-ivd-mapper>
          <ng-container IVD>
            <app-days-visual id="days-since-specimen-collection-tooltip-{{row.id}}" *ngIf="showDaysSinceColumn && row.receiptDate; else emptyDaysSinceCellIvd" [count1]="row.daysSinceSpecimenCollection">
            </app-days-visual>
            <owc-tooltip i18n="CaseList.Tooltip@@days-since-tooltip-ivd" anchor="days-since-specimen-collection-tooltip-{{row.id}}" class="days-since-specimen-collection-tooltip-{{row.id}}" auto-unportal>
              Sample Receipt: {{row.receiptDate | dateFormat:localization.dateFormat}}, Case created: {{row.createdAt | dateFormat:localization.dateFormat}}
            </owc-tooltip>
            <ng-template #emptyDaysSinceCellIvd>
              <div class="cair-color-gray-400" id="empty-days-since-specimen-collection-tooltip-{{row.id}}">
                <span *ngIf="showDaysSinceColumn; else emptyCell"> – </span>
              </div>
              <owc-tooltip i18n="CaseList.Tooltip@@empty-days-since-tooltip-ivd" anchor="empty-days-since-specimen-collection-tooltip-{{row.id}}" class="empty-days-since-specimen-collection-tooltip-{{row.id}}" auto-unportal>
                Case created: {{row.createdAt | dateFormat:localization.dateFormat}}
              </owc-tooltip>
            </ng-template>
          </ng-container>
          <ng-container RUO>
            <app-days-visual *ngIf="showDaysSinceColumn && row.receiptDate; else emptyDaysSinceCellRuo" id="days-since-specimen-collection-tooltip-ruo-{{row.id}}" [count1]="row.daysSinceSpecimenCollection">
            </app-days-visual>
            <owc-tooltip i18n="CaseList.Tooltip@@days-since-tooltip-ruo" anchor="days-since-specimen-collection-tooltip-ruo-{{row.id}}" class="days-since-specimen-collection-tooltip-ruo-{{row.id}}" auto-unportal>
              Sample Receipt: {{row.receiptDate | dateFormat:localization.dateFormat}}, Research case created: {{row.createdAt | dateFormat:localization.dateFormat}}
            </owc-tooltip>
            <ng-template #emptyDaysSinceCellRuo>
              <div class="cair-color-gray-400" id="empty-days-since-specimen-collection-tooltip-ruo-{{row.id}}">
                <span *ngIf="showDaysSinceColumn; else emptyCell"> – </span>
              </div>
              <owc-tooltip i18n="CaseList.Tooltip@@empty-days-since-tooltip-ruo" anchor="empty-days-since-specimen-collection-tooltip-ruo-{{row.id}}" class="empty-days-since-specimen-collection-tooltip-ruo-{{row.id}}" auto-unportal>
                Research case created: {{row.createdAt | dateFormat:localization.dateFormat}}
              </owc-tooltip>
            </ng-template>
          </ng-container>
        </app-ruo-ivd-mapper>
      </owc-table-cell>
      <owc-table-cell class="status-table-cell" (click)="navigateTo(row)">
        <div id="status-{{row.id}}">
          <app-case-status [statusType]="row.status"></app-case-status>
          <div class="owc-caption cair-color-gray-400 changed-by">
            <app-user-name [userID]="row.lastModifiedByUserId"></app-user-name>
          </div>
        </div>
        <owc-tooltip anchor="status-{{row.id}}" class="status-{{row.id}}" auto-unportal>
          {{ row.lastModifiedAt | dateFormat:localization.dateFormat }}
        </owc-tooltip>
      </owc-table-cell>
      <owc-table-cell class="diagnosis-table-cell" (click)="navigateTo(row)">
        <span>{{row.diagnosis}}</span>
      </owc-table-cell>
      <owc-table-cell class="variants-table-cell" (click)="navigateTo(row)">
        <ng-container *ngFor="let i of [0,1]">
          <div *ngIf="row.variants && row.variants[i]">
            <app-variant-title [name]="row.variants[i]"></app-variant-title>
          </div>
        </ng-container>
        <span id="variants-{{row.id}}" class="moreVariantText owc-caption" *ngIf="(row.variantCount - MAX_VARIANTS_TOBE_DISPLAYED_ON_LIST) > 0">
          <!-- Please do not remove "+". It's part of display format: "+N more" -->
          +{{row.variantCount - MAX_VARIANTS_TOBE_DISPLAYED_ON_LIST}} <span i18n="CaseList.Td@@more@@ordering-institution-and-physician---not-available">more</span>
        </span>
        <owc-tooltip class="case-list-variants-tooltip" anchor="variants-{{row.id}}" class="variants-{{row.id}}" auto-unportal>
          <span>{{ getVariantListByToolTip(row) }}</span>
        </owc-tooltip>
      </owc-table-cell>
      <owc-table-cell class="order-by-table-cell" (click)="navigateTo(row)">
        <owc-icon id="order-by-{{row.id}}" class="dim-icon cursor-default" *ngIf="!row.orderingPhysicianName && !row.orderingInstituteName; else orderingPhyInst" name="business" family="outlined"></owc-icon>
        <owc-tooltip anchor="order-by-{{row.id}}" class="order-by-{{row.id}}" auto-unportal>
          <app-ruo-ivd-mapper>
            <ng-container i18n="No subscribers tooltip for IVD@@no-subscribers" IVD>
              Ordering institution and physician - not available
            </ng-container>
            <ng-container i18n="No subscribers tooltip for RUO@@no-subscribers" RUO>
              Institution name and contact - not available
            </ng-container>
          </app-ruo-ivd-mapper>
        </owc-tooltip>
        <ng-template #orderingPhyInst>
          <owc-icon id="order-by-phy-institute-{{row.id}}" class="cursor-default" name="business" family="outlined"></owc-icon>
          <owc-tooltip anchor="order-by-phy-institute-{{row.id}}" class="order-by-phy-institute-{{row.id}}" auto-unportal>
            {{ getOrderByTooltip(row) }}
          </owc-tooltip>
        </ng-template>
      </owc-table-cell>
      <owc-table-cell class="subscription-table-cell" (click)="navigateTo(row)">
        <ng-container *ngIf="row.subscribers?.length > 0; else noSubscribersIcon">
          <owc-icon id="subscription-{{row.id}}" class="cursor-default subscribed-icon" *ngIf="row.subscribed; else notSubscribedIcon">
            <img src="assets/icons/baseline-how_to_reg-24px.svg" alt="" />
          </owc-icon>
          <owc-tooltip anchor="subscription-{{row.id}}" class="subscription-{{row.id}}" auto-unportal>
            {{ subscriberMap[row.id] | async }}
          </owc-tooltip>
          <ng-template #notSubscribedIcon>
            <owc-icon id="no-subscription-{{row.id}}" class="cursor-default subscribed-icon" name="user_group" family="outlined"></owc-icon>
            <owc-tooltip anchor="no-subscription-{{row.id}}" class="no-subscription-{{row.id}}" auto-unportal>
              {{ subscriberMap[row.id] | async }}
            </owc-tooltip>
          </ng-template>
        </ng-container>
        <ng-template #noSubscribersIcon>
          <owc-icon id="no-case-subscription-{{row.id}}" class="dim-icon cursor-default no-case-subscribers" name="user_group" family="outlined"></owc-icon>
          <owc-tooltip i18n="@@no-subscribers" anchor="no-case-subscription-{{row.id}}" class="no-case-subscription-{{row.id}}" auto-unportal>
            no subscribers
          </owc-tooltip>
        </ng-template>
      </owc-table-cell>
      <owc-table-cell class="acitvities-table-cell" (click)="navigateTo(row)">
        <!-- Count of activities = Lab notes + Other case activities count -->
        <app-case-event-notification [rowId]="row.id" class="cursor-default" [count]="(row.labNoteCount || 0) + (row.eventCount || 0)"
        [hasError]="false" [smallSize]="true"></app-case-event-notification>
      </owc-table-cell>
      <owc-table-cell class="actions-table-cell">
        <app-case-list-actions [case]="row"></app-case-list-actions>
      </owc-table-cell>
    </owc-table-row>

    <div class="message" *ngIf="(checkListEmpty$ | async) && !(loading | async)">
      <ng-container [ngSwitch]="listName">
        <app-ruo-ivd-mapper *ngSwitchCase="ListName.inProgress">
          <span i18n="CaseList.Notes@@no-cases-in-progress" IVD>No cases in progress</span>
          <span i18n="CaseList.Notes@@no-research-cases-in-progress" RUO>No research cases in progress</span>
        </app-ruo-ivd-mapper>

        <app-ruo-ivd-mapper *ngSwitchCase="ListName.toApprove">
          <span i18n="CaseList.Notes@@no-cases-to-approve" IVD>No cases to approve</span>
          <span i18n="CaseList.Notes@@no-research-cases-to-confirm" RUO>No research cases to confirm</span>
        </app-ruo-ivd-mapper>

        <app-ruo-ivd-mapper *ngSwitchCase="ListName.signed">
          <span i18n="CaseList.Notes@@no-approved-cases" IVD>No approved cases</span>
          <span i18n="CaseList.Notes@@no-confirm-research-cases" RUO>No confirmed research cases</span>
        </app-ruo-ivd-mapper>

        <app-ruo-ivd-mapper *ngSwitchCase="ListName.canceled">
          <span i18n="CaseList.Notes@@no-canceled-cases" IVD>No canceled cases</span>
          <span i18n="CaseList.Notes@@no-canceled-research-cases" RUO>No canceled research cases</span>
        </app-ruo-ivd-mapper>
      </ng-container>
    </div>
  </owc-table-body>
  <ng-container *ngIf="pagerConfig$ | async as pagerConfig">
    <!-- Only show pagination component when total number of cases are above 25 -->
    <div slot="footer" *ngIf="pagination && totalCases > pageSizes.twentyFive">
      <owc-pagination
        [page]="pagerConfig.page"
        [total]="pagerConfig.total"
        [options]="paginationPageSizeOptions"
        [rowsPerPage]="pagerConfig.rowsPerPage"
        (pageChange)="pageChangeHandler($event.detail)"
      ></owc-pagination>
    </div>
  </ng-container>
</owc-table>

<ng-template #emptyCell>&nbsp;</ng-template>
